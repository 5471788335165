<template>
  <v-dialog
    :retain-focus="false"
    v-model="modalIsShow"
    width="700"
    @click:outside="closeModal"
  >
    <v-card>
      <v-card-title
        class="white--text primary"
        align="left"
      >
        Опис
      </v-card-title>
      <v-container>
        <v-textarea
          rows="1"
          auto-grow
          v-model="item.title"
          label="Назва"
        />
        <v-textarea
          rows="1"
          auto-grow
          v-model="desc"
          label="Опис"
        />
        <p>Галерея</p>
        <v-row>
          <v-col
            v-for="(image, i) in gallery"
            :key="image + i"
            cols="12"
            md-6
          >
            <v-img
              :src="image"
              class="white--text align-end"
              contain
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="default"
          @click="closeModal()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ItemModal',
  props: {
    item: {
      type: Object,
      required: true
    },
    modalIsShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      desc: 'Чудова 3-кімнатна квартира з великими вікнами, якісним ремонтом та меблями. Простора кухня-вітальня, де ви зможете комфортно проводити час. Кожна спальня обладнана всім необхідним для відпочинку. Два санвузли, обладнані сучасними технічними рішеннями. Зручне місце розташування в центрі міста, поруч з парком і транспортною розв\'язкою. Це ідеальний варіант для сучасної сім\'ї або тих, хто цінує комфорт і якість життя',
      gallery: [
        'https://i.natgeofe.com/n/c9107b46-78b1-4394-988d-53927646c72b/1095.jpg',
        'https://www.exoticca.com/uk/magazine/wp-content/uploads/2021/06/matterhorn-BLOG-mountains.png',
        'https://images.pexels.com/photos/417173/pexels-photo-417173.jpeg?cs=srgb&dl=pexels-pixabay-417173.jpg&fm=jpg',
        'https://www.muchbetteradventures.com/magazine/content/images/2022/05/mont-blanc.jpg'
      ]
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    }
  }
};
</script>
